:root {
  --general-padding: 2rem;
  --general-border-radius: 1rem;
  --primary: #302783;
}

body {
  padding: var(--general-padding);
}

p { white-space: pre-line; margin: 0 }
h4 { white-space: pre-line; margin: 0 }


.grid {
  display: grid;
  gap: var(--general-padding);
}

.fl-1 {
  flex: 1
}

.btn-primary {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}
.btn-outline-primary:hover {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}

.pointer { cursor: pointer; transition-duration: 300ms; }

td { white-space: pre-line; }
th, tr, td {
  text-transform: none!important;
}

.text-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.text-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.appbar > div > h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn:focus { outline: none; box-shadow: none; }

.grid-2 { grid-template-columns: repeat(2, 1fr) }
.grid-3 { grid-template-columns: repeat(3, 1fr) }
.grid-4 { grid-template-columns: repeat(4, 1fr) }
.grid-6 { grid-template-columns: repeat(6, 1fr) }

.grid-slim { gap: 0.5rem!important }

.grid-search-event {grid-template-columns: 5fr 2fr 1fr}

.grid {
  display: grid;
  gap: calc(var(--general-padding));
}

.app-wrapper { display: flex; flex-direction: row; }
.app-content { flex: 1; margin-left: var(--general-padding); position: relative; height: fit-content; }

nav, .main, .sidebar {
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  padding: 1rem;
  background-color: #fff;
}

form {
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  padding: 1rem;
  background-color: #fff;
  display: grid;
  gap: 1rem;
}
form > .grid { gap: 1rem; }
form > .grid > .grid { gap: 1rem }
form > details > .grid { gap: 1rem }
form > .grid > .grid > .grid { gap: 1rem }
form > .grid > .grid > .grid > .grid { gap: 1rem }

.appbar {
  /* position: -webkit-sticky; */
  /* position: sticky; */
  /* top: var(--general-padding); */
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}

td > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
}


.async-entity-dropdown {
  display: inline-block;
  position: absolute;
  width: 75%;
  max-height: 12rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-menu-item:not(:last-child) {
  border-bottom: 1px solid #DADADA;
}

.dropdown-menu-item {
  padding: 0.5rem
}
.dropdown-menu-item:hover {
  background-color: gray;
}

.dropdown-menu-item > a {
  color: black;
  text-decoration: none;
}

.dropdown-menu {
  padding: 0;

}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: calc(100vh - var(--general-padding) * 2);
  position: -webkit-sticky;
  position: sticky;
  top: var(--general-padding);
}

.center { 
  left: 50%; top: 50%; 
  transform: translate(-50%, -50%)
}

.sidebar > .menu {
  flex: 1;
  overflow-y: scroll;
}



tr:last-child {
  border-bottom: none!important;
}

table.inline {
  box-shadow: none;
  padding: 0;
}

table > thead {
  vertical-align: bottom;
}

th,tr,td {
  padding: 1rem 1rem;
  text-transform: capitalize;
  letter-spacing: 0;
  border-bottom: 1px solid #e9ecef!important;
  border-top: 1px solid #e9ecef!important;
}

.table td, .table th:last-child {
  border-bottom: none!important;

}

.engine {
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  width: 150%;
  height: 150%;
  background-color: #00000055;
  z-index: 1;
}

.modal-overlay {
  background-color: #000000aa;
  position: absolute;
}

.button-like {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.event-tile-image {
  object-fit: cover;
  aspect-ratio: 1/1;
}

.event-tile-wrapper > div {
  position: absolute;
  background-color: #454545DA;
  color: white!important;
  width: 100%;
  bottom: 0;
}

.mobile-only { display: none }

.dashboard-table {
  grid-column: 2/5;
  grid-row: 1
}

.info {
  position: relative;
}

.info:hover .info-text {
  display:block;
}

.info-text {
  display: none;
  color: white;
  background-color: #017bfe;
  font-weight: normal;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 300px;
  text-align: center;
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, calc(-100% - 1rem));
  position: absolute;
  z-index: 1000;
}

.info-text::after{
  content: '';
  position: absolute;
  left: 50%;
  top: calc(100% - 0.125rem);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #017bfe;
  clear: both;
}

.seating-plan {
  width: 100%;
  border: none;
  min-height: 66vh;
}

.seating-menu {
  padding: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.seating-engine {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: #000000aa;
}

.seating-engine > section {
  height: 75vh;
  width: 75vw;
}

.promoted-event-dialog {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: #000000aa;
}

.promoted-event-window {
  position: relative;
  height: 75vh!important;
  aspect-ratio: 9/16;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
}

.promoted-event-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(0.5rem);
  transform: scale(1.1);
}

.promoted-event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.dropBox {
  width: 100%!important;
}

.new-event-card {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  background-color: #fff;
  background-size: 100%;
}

.left-pad-sidebar {
  /* Half of sidebar */
  margin-left: 7.5rem;
}

.min-height {
  min-height: 200px;
}

/* Tablets */
@media only screen and (max-width: 768px) {
  body { padding: calc(var(--general-padding)/2) }

  .fw-100-m { width: calc(100vw - var(--general-padding)); }

  .mini-events {
    display: flex;
    width: calc(100vw - var(--general-padding));
    overflow-x: scroll;
  }

  .mini-events > div {
    margin-right: 1rem;
  }
  .mini-events > div:last-child {
    margin-right: 0;
  }

  .dashboard-table {
    height: 20rem;
  }

  .mobile-only { display: unset }

  .grid-2 { grid-template-columns: repeat(1, 1fr) }
  .grid-3 { grid-template-columns: repeat(3, 1fr) }
  .grid-4 { grid-template-columns: repeat(2, 1fr) }
  .grid-6 { grid-template-columns: repeat(3, 1fr) }

  .grid-1-m { grid-template-columns: repeat(1, 1fr)!important; }

  .dashboard-table {
    grid-column: 1/1;
    grid-row: 2
  }

  .sidebar {
    position: absolute;
    top: 6.5rem;
    right: -100%;
    z-index: 1;
    width: calc(((100vw - 2rem)));
    height: calc(100vh - 7.5rem);
    transition-duration: 400ms;
  }

  .sidebar-show {
    right: 1rem;
  }

  .app-content {
    margin: 0;
  }

  .grid { gap: calc(var(--general-padding) / 2) }

  .appbar {
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--general-padding) / 2);
    z-index: 999;
  }
  .left-pad-sidebar {
    margin-left:0 ;
  }
}

.map {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}

.map > iframe {
  position: absolute;
  width: 250%;
  height: 200%;
}

label {
  font-weight: bold;
}
